<!--
  //  AppNavigation.vue
  //  croppa-web
  //
  //  Created by Matthew Higham on 12/08/2019
  //  Copyright © 2019 CroppaCo Pty. Ltd. All rights reserved.
  -->

<template>
    <span>
        <v-app-bar min-height="58px" app color="#DEDEDE">
            <img src="@/assets/icon-fs-orange.svg" height="50px" class="mr-5" />
            <v-toolbar-title style="color: #4f4f4f"><b>FARM</b>SIMPLE Console</v-toolbar-title>
            <v-spacer />
            <span id="userDetails">{{ userDetails.firstName }} {{ userDetails.lastName }} </span>
            |
            <span v-bind:style="{ color: userPermission.colour }" id="userDetails"> {{ userPermission.text }}</span>
            <v-btn @click="logout" color="deep-orange darken-2" class="white--text">Logout </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            width="250"
            class="blue-grey darken-4"
            dark
            permanent
            app
            :mini-variant="miniVariant"
            v-model="drawer"
        >
            <v-list dense>
                <v-list-item>
                    <v-btn icon @click="miniVariant = !miniVariant" v-if="miniVariant" class="ml-n1">
                        <v-icon>{{ mdiChevronRight }}</v-icon>
                    </v-btn>

                    <v-list-item-title>{{ general.name }}</v-list-item-title>
                    <v-btn icon @click="miniVariant = !miniVariant">
                        <v-icon>{{ mdiChevronLeft }}</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>

            <v-divider />

            <v-list dense>
                <v-list-item key="Dashboard" @click="itemClicked('Dashboard')">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <img v-on="on" src="@/assets/icon-dashboard.png" width="25" height="25" />
                            </template>
                            <span>Dashboard</span>
                        </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Dashboard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!--<v-list-item key="CustomReports" @click="itemClicked('CustomReports')">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <img v-on="on" src="@/assets/icon-reports.png" width="25" height="25" />
                            </template>
                            <span>Custom Reports</span>
                        </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Custom Reports</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>-->
            </v-list>

            <v-divider />

            <v-list dense style="padding-bottom: 0">
            <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="Jobs">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-jobs.png" width="25" height="25" />
                                    </template>
                                    <span>Jobs</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title> Jobs </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4" max-height="80vh" class="overflow-y-auto">
                        <v-list-item
                            v-for="(item, index) in jobItems"
                            :key="index"
                            @click="itemClicked(item.title)"
                        >
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>

            <v-list dense style="padding: 0 0 0 0">
                <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="Paddocks">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-paddocks.png" width="25" height="25" />
                                    </template>
                                    <span>Activities</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title> Activities </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4" max-height="80vh" class="overflow-y-auto">
                        <v-list-item
                            v-for="(item, index) in paddockItems"
                            :key="index"
                            @click="itemClicked(item.title)"
                        >
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>

            <v-list dense style="padding: 0 0 0 0">
                <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="Inventory">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-storage.png" width="25" height="25" />
                                    </template>
                                    <span>Inventory</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Inventory</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4" max-height="80vh" class="overflow-y-auto">
                        <v-list-item
                            v-for="(item, index) in inventoryItems"
                            :key="index"
                            @click="itemClicked(item.title)"
                        >
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>

            <v-list dense style="padding-top: 0">
                <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="Personnel">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-personnel.png" width="25" height="25" />
                                    </template>
                                    <span>Personnel</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Personnel</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4" max-height="80vh" class="overflow-y-auto">
                        <v-list-item
                            v-for="(item, index) in calcPersonnelItems"
                            :key="index"
                            @click="itemClicked(item.title)"
                        >
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>

            <v-divider />

            <v-list dense style="padding: 0 0 0 0">
                <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="FarmAssets">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-farm.png" width="25" height="25" />
                                    </template>
                                    <span>Farm Assets</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Farm Assets</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4" max-height="80vh" class="overflow-y-auto">
                        <v-list-item v-for="(item, index) in calcAssetItems" :key="index" @click="itemClicked(item.title)">
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>

            <v-divider />

            <v-list dense style="padding: 0 0 0 0">
                <v-list-item key="Calendar" @click="itemClicked('Calendar')">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <img v-on="on" src="@/assets/icon-calendar.png" width="25" height="25" />
                            </template>
                            <span>Calendar</span>
                        </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Calendar</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider />

            <v-list dense style="padding: 0 0 0 0">
                <v-list-item key="Switch Sub" @click="itemClicked('Switch Sub')">
                    <v-list-item-action>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <img v-on="on" src="@/assets/icon-switch-sub.png" width="25" height="25" />
                            </template>
                            <span>Switch Client</span>
                        </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>Switch Client</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <!--
            <v-divider />
            <v-list dense style="padding: 0 0 0 0">
                <v-menu dark offset-x>
                    <template v-slot:activator="{ on }">
                        <v-list-item v-on="on" key="Integrations">
                            <v-list-item-action>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <img v-on="on" src="@/assets/icon-integrations.png" width="25" height="25" />
                                    </template>
                                    <span>Integrations</span>
                                </v-tooltip>
                            </v-list-item-action>

                            <v-list-item-content>
                                <v-list-item-title>Integrations</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list min-width="170" color="blue-grey darken-4">
                        <v-list-item
                            v-for="(item, index) in integrationItems"
                            :key="index"
                            @click="itemClicked(item.title)"
                        >
                            <v-list-item-action>
                                <img :src="item.icon" width="25" height="25" />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list>-->

            <v-divider v-if="!miniVariant" />

            <br />
            <div v-if="!miniVariant" id="footerContainer">
                <span width="250px" small class="innerSpan">
                    <a id="legalText" target="_blank" href="https://croppaco.com/farm-simple-terms-of-use">Terms of Use</a>
                </span>

                <br />

                <span small>
                    <a id="legalText" target="_blank" href="https://croppaco.com/farm-simple-privacy-policy">Privacy Policy</a>
                </span>

                <br />
                <br />

                <span small id="smallText">
                    Icons by <a target="_blank" href="https://icons8.com">Icons8</a>
                </span>
            </div>
            <br />
        </v-navigation-drawer>
    </span>
</template>

<script>
import { mapState } from 'vuex';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export default {
    name: 'AppNavigation',
    data() {
        return {
            mdiChevronLeft, 
            mdiChevronRight,
            drawer: false,
            miniVariant: true,
            showMenu: false,
            settingItems: [
                { title: 'Switch Sub', icon: require('@/assets/icon-switch-sub.png')}
            ],
            assetItems: [
                { title: 'Farms', icon: require('@/assets/icon-paddocks.png') },
                { title: 'Rain', icon: require('@/assets/icon-rain-gauge.png') },
                { title: 'Cultivars', icon: require('@/assets/icon-cultivar.png') },
                { title: 'Livestock', icon: require('@/assets/icon-livestock.png') },
                { title: 'Vet Products', icon: require('@/assets/icon-vet-products.png') },
                { title: 'Equipment', icon: require('@/assets/icon-equipment.png') },
                { title: 'Chemicals', icon: require('@/assets/icon-chemical.png') },
                { title: 'Fertilisers', icon: require('@/assets/icon-fertiliser.png') },
                { title: 'Users', icon: require('@/assets/icon-user.png') },
                { title: 'Contractors', icon: require('@/assets/icon-contractor.png') },
                { title: 'Nozzles', icon: require('@/assets/icon-nozzle.png') },
                { title: 'Water Licences', icon: require('@/assets/icon-water-licence.png') },
                { title: 'Work Types', icon: require('@/assets/icon-work-type.png') },
                { title: 'Checklist Templates', icon: require('@/assets/icon-checklist-template.png') }
            ],
            enterpriseItems: [
                { title: 'Cost Codes', icon: require('@/assets/icon-cost-codes.png') }
            ],
            jobItems: [
                { title: 'Jobs', icon: require('@/assets/icon-jobs.png') },
                { title: 'Equipment Inspections', icon: require('@/assets/icon-equipment-inspection.png') },
                { title: 'Pump Readings', icon: require('@/assets/icon-pump-reading.png') },
                { title: 'Purchase Orders', icon: require('@/assets/icon-purchase-orders.png') }
            ],
            paddockItems: [
                { title: 'Activity Reports', icon: require('@/assets/icon-reports.png') },
                { title: 'Spray Logs', icon: require('@/assets/icon-spraylogs.png') },
                { title: 'Planting', icon: require('@/assets/icon-planting.png') },
                { title: 'Spreading', icon: require('@/assets/icon-spreading.png') },
                { title: 'Harvest', icon: require('@/assets/icon-harvest.png') },
                { title: 'Maintenance', icon: require('@/assets/icon-maintenance.png') },
                { title: 'Irrigation', icon: require('@/assets/icon-irrigation.png') },
                { title: 'Soil Testing', icon: require('@/assets/icon-paddocks.png') },
                { title: 'Grazing', icon: require('@/assets/icon-grazing.svg') },
                { title: 'Vet Treatments', icon: require('@/assets/icon-vet-treatment.png') }
            ],
            inventoryItems: [
                { title: 'Inventory Reports', icon: require('@/assets/icon-reports.png') },
                { title: 'Grain', icon: require('@/assets/icon-grain.png') },
                { title: 'Chemical', icon: require('@/assets/icon-chemical.png') },
                { title: 'Fertiliser', icon: require('@/assets/icon-fertiliser.png') },
                { title: 'Fuel', icon: require('@/assets/icon-fuel.png') },
                { title: 'Water', icon: require('@/assets/icon-water-storage.png') }
            ],
            personnelItems: [
                { title: 'Timesheets', icon: require('@/assets/icon-timesheets.png') },
                { title: 'Qualifications', icon: require('@/assets/icon-qualification.svg') }
            ],
            hrAssetItems : [
                { title: 'Induction Templates', icon: require('@/assets/icon-inductions.png') }
            ],
            hrItems: [
                { title: 'Inductions', icon: require('@/assets/icon-inductions.png') },
                { title: 'Toolbox Meetings', icon: require('@/assets/icon-toolbox-meetings.png') },
                { title: 'Hazard Reports', icon: require('@/assets/icon-hazard-reports.png') }
            ],
            integrationItems: [{ title: 'Xero', icon: require('@/assets/xero/xero-logo-blue.svg') }]
        };
    },
    computed: {
        ...mapState(['general', 'userDetails']),
        enterpriseFeaturesUnlocked() {
            return this.$store.getters.areEnterpriseFeaturesUnlocked;
        },
        hrFeaturesUnlocked() {
            return this.$store.getters.areHrFeaturesUnlocked;
        },
        userPermission() {
            // Set Permissions Label
            if (this.userDetails.permissions == 0xaa) {
                return { text: 'Owner', colour: '#F44336' };
            } else if (this.userDetails.permissions == 0xa5) {
                return { text: 'Admin', colour: '#FF9800' };
            } else if (this.userDetails.permissions == 0x55) {
                return { text: 'Advisor', colour: '#673AB7' };
            } else {
                return { text: 'User', colour: '#2196F3' };
            }
        },
        calcAssetItems() {
            if (this.enterpriseFeaturesUnlocked || this.hrFeaturesUnlocked) {
                if (this.enterpriseFeaturesUnlocked && this.hrFeaturesUnlocked) {
                    return this.assetItems.concat(this.hrAssetItems, this.enterpriseItems);
                }
                else if (this.enterpriseFeaturesUnlocked) {
                    return this.assetItems.concat(this.enterpriseItems);
                }
                return this.assetItems.concat(this.hrAssetItems);
            }
            return this.assetItems;
        },
        calcPersonnelItems() {
            if (this.hrFeaturesUnlocked) {
                return this.personnelItems.concat(this.hrItems);
            }
            return this.personnelItems;
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('userSignOut');
        },
        itemClicked(item) {
            this.$store.commit('setActivePage', item);
        }
    }
};
</script>

<style scoped>
a {
    color: white;
    text-decoration: none;
}
span {
    vertical-align: middle;
    margin-right: 10px;
}
#userDetails {
    line-height: normal;
    vertical-align: baseline;
    color: '#4F4F4F';
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
}
#footerContainer {
    text-align: center;
}
.innerSpan {
    display: inline-block;
    position: relative;
}
#smallText {
    color: white;
    font-size: x-small;
}
#legalText {
    color: white;
    font-size: small;
    margin-left: auto;
    margin-right: auto;
}
</style>
